import jobApplicantHandler from "@/services/JobApplicant";

export default {
    // eslint-disable-next-line no-unused-vars
    async loadApplicants({commit, dispatch}, payload) {
        const {data} = await jobApplicantHandler.all(payload);

        commit('SET_APPLICANT', data);
    },
    // eslint-disable-next-line no-unused-vars
    async storeApplicant({commit, dispatch}, payload) {
        return jobApplicantHandler.store(payload);
    },
    // eslint-disable-next-line no-unused-vars
    async updateApplicant({commit, dispatch}, payload) {
        return jobApplicantHandler.update(payload);
    },
    // eslint-disable-next-line no-unused-vars
    async deleteApplicant({commit, dispatch}, payload) {
        return jobApplicantHandler.remove(payload);
    },
    // eslint-disable-next-line no-unused-vars
    async destroyApplicant({commit, dispatch}, payload) {
        return jobApplicantHandler.destroy(payload);
    },
    // eslint-disable-next-line no-unused-vars
    async restoreApplicant({commit, dispatch}, payload) {
        return jobApplicantHandler.restore(payload);
    },
}
