import Api from "./Api";

const allPosts = async (payload) => {
    const path = '/posts';
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;

    return Api.getRequest({
        endpoint: endpoint
    })
}

const get = async (payload) => {
    const path = '/post/get';
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;

    return Api.getRequest({
        endpoint: endpoint
    })
}

const slug = async (data) => {
    return Api.postRequest({
        endpoint: '/post/slug',
        data: data
    })
}

const store = async (data) => {
    return Api.postRequest({
        endpoint: '/post/store',
        data: data
    })
}

const update = async (data) => {
    return Api.postRequest({
        endpoint: '/post/update',
        data: data
    })
}

const remove = async (data) => {
    return Api.deleteRequest({
        endpoint: `/post/delete/${data.id}`
    })
}

export default {
    allPosts,
    get,
    slug,
    store,
    update,
    remove
};
