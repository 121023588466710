import employeeTypeHandler from "@/services/EmployeeType";

export default {
    // eslint-disable-next-line no-unused-vars
    async loadEmployeeTypes({commit, dispatch}, payload) {
        const {data} = await employeeTypeHandler.all(payload);

        commit('SET_EMPLOYEE_TYPES', data);
    },
    // eslint-disable-next-line no-unused-vars
    async storeEmployeeType({commit, dispatch}, payload) {
        return employeeTypeHandler.store(payload);
    },
    // eslint-disable-next-line no-unused-vars
    async updateEmployeeType({commit, dispatch}, payload) {
        return employeeTypeHandler.update(payload);
    },
    // eslint-disable-next-line no-unused-vars
    async deleteEmployeeType({commit, dispatch}, payload) {
        return employeeTypeHandler.remove(payload);
    },
    // eslint-disable-next-line no-unused-vars
    async destroyEmployeeType({commit, dispatch}, payload) {
        return employeeTypeHandler.destroy(payload);
    },
    // eslint-disable-next-line no-unused-vars
    async restoreEmployeeType({commit, dispatch}, payload) {
        return employeeTypeHandler.restore(payload);
    },
}
