import hiringPorcessHandler from "@/services/HiringPorcess";

export default {
    // eslint-disable-next-line no-unused-vars
    async loadHiringProcesses({commit, dispatch}, payload) {
        const {data} = await hiringPorcessHandler.all(payload);

        commit('SET_HIRING_PROCESSES', data);
    },
    // eslint-disable-next-line no-unused-vars
    async storeHiringProcess({commit, dispatch}, payload) {
        return hiringPorcessHandler.store(payload);
    },
    // eslint-disable-next-line no-unused-vars
    async updateHiringProcess({commit, dispatch}, payload) {
        return hiringPorcessHandler.update(payload);
    },
    // eslint-disable-next-line no-unused-vars
    async deleteHiringProcess({commit, dispatch}, payload) {
        return hiringPorcessHandler.remove(payload);
    },
    // eslint-disable-next-line no-unused-vars
    async destroyHiringProcess({commit, dispatch}, payload) {
        return hiringPorcessHandler.destroy(payload);
    },
    // eslint-disable-next-line no-unused-vars
    async restoreHiringProcess({commit, dispatch}, payload) {
        return hiringPorcessHandler.restore(payload);
    },
}
