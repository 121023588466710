import userHandler from "@/services/User";

export default {
    // eslint-disable-next-line no-unused-vars
    async register({commit, dispatch}, payload) {
        return userHandler.register(payload);
    },
    // eslint-disable-next-line no-unused-vars
    async login({commit, dispatch}, payload) {
        return userHandler.login(payload);
    },
    // eslint-disable-next-line no-unused-vars
    async logout({commit, dispatch}, payload) {
        return userHandler.logout();
    },
    // eslint-disable-next-line no-unused-vars
    async auth({commit, dispatch}, payload) {
        return userHandler.auth();
    },

    // eslint-disable-next-line no-unused-vars
    async csrf({commit, dispatch}, payload) {
        return userHandler.csrf();
    }
}
