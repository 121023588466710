<template>
  <transition name="slide-fade">
    <v-footer app light>
      <span class="text-body-2 black--text">&copy;&nbsp;{{ getYear() }} by &nbsp;<a href="https://clockscore.com/" style="text-decoration: none" target="_blank">CLOCKSCORE.COM</a></span>
      <span class="text-body-2 black--text">&nbsp;- All Rights Reserved.</span>
    </v-footer>
  </transition>
</template>

<script>
import moment from "moment";

export default {
  name: "AppFooter",
  methods: {
    getYear() {
      return moment().year();
    }
  }
}
</script>

<style scoped>

</style>
