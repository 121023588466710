import Api from "./Api";
const register = async (data) => {
    return Api.postRequest({
        endpoint: '/register',
        data: data
    })
}

const login = async (data) => {
    return Api.postRequest({
        endpoint: '/login',
        data: data
    })
}

const logout = async () => {
    return Api.postRequest({
        endpoint: '/logout',
    })
}

const auth = async () => {
    return Api.getRequest({endpoint: '/user'});
}

const csrf = async () => {
    return Api.getRequest({endpoint: '/sanctum/csrf-cookie'});
}

export default {
    register,
    login,
    logout,
    auth,
    csrf
};
