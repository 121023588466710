import {mapState} from "vuex";

export default {
    computed: {
        ...mapState({
            user: state => state.user
        }),
        isAdmin() {
            return this.user && this.user.role_id === 1;
        }
    }
}