import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import user from "./user";
import department from "./department";
import location from "./location";
import employeeType from "./employeeType";
import hiringProcess from "./hiringProcess";
import job from "./job";
import post from "./post";
import jobApplicant from "./jobApplicant";

export default new Vuex.Store({
  modules: {
    user,
    department,
    location,
    employeeType,
    hiringProcess,
    job,
    post,
    jobApplicant
  },
  state: {
    snackBar: {},
    user: null,
    showLoading: false,
  },
  mutations: {
    SET_SNACKBAR: function (state, payload) {
      state.snackBar = payload
    },
    SET_LOADING: function (state, payload) {
      state.showLoading = payload
    },
    SET_USER: function (state, payload) {
      state.user = payload
    }
  }
})
