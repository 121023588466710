<template>
  <div>
    <v-card :light="!$vuetify.theme.dark" class="ma-2" color="#fafafa" elevation="0">
      <v-card-text>
        <router-link to="/">
          <v-img
              :src="logo"
              max-height="50"
          />
        </router-link>
      </v-card-text>
    </v-card>
    <v-divider/>
    <v-list rounded>
      <template v-for="(item, key) in menus">
        <v-list-group
            v-if="item.subMenuItems"
            :prepend-icon="item.icon"
            color="#fafafa"
            :key="key"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{item.title}}</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
              v-for="(subMenu, i) in item.subMenuItems"
              :key="i"
              :to="subMenu.path"
              color="#fafafa"
              router
              link
              exact
          >
            <v-list-item-icon>
              <v-icon v-text="subMenu.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-title v-text="subMenu.title"></v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-item
            v-else
            :to="item.path"
            color="#fafafa"
            router
            link
            exact
            :key="key"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </div>
</template>

<script>
export default {
  name: "AppSidebarNav",
  data() {
    return {
      menus: [
        {title: 'Dashboard', icon: 'mdi-view-dashboard', path: '/'},
        {title: 'Department', icon: 'mdi-store', path: '/department'},
        {title: 'Location', icon: 'mdi-map-marker', path: '/location'},
        {title: 'Employee Type', icon: 'mdi-format-list-bulleted-type', path: '/employee-type'},
        {title: 'Hiring Process', icon: 'mdi-message-processing', path: '/hiring-process'},
        {title: 'Job', icon: 'mdi-account-network', path: '/job'},
        {title: 'Job Applicant', icon: 'mdi-book-open-variant', path: '/job-applicant'},
        {
          title: 'Page',
          icon: 'mdi-flip-to-front',
          subMenuItems: [
            {title: 'About Us', icon: 'mdi-information-outline', path: '/about-us'},
            {title: 'Join Us', icon: 'mdi-infinity', path: '/join-us'},
            {title: 'Privacy Policy', icon: 'mdi-lock-reset', path: '/privacy-policy'}
          ]
        }
      ],
      logo: require('@/assets/img/logo.png')
    }
  },
  props: {
    miniVariant: {
      type: Boolean,
      default: () => false
    }
  }
}
</script>

<style lang="scss" scoped>
.v-navigation-drawer {
  .company-logo-panel {
    width: 100%;
    text-align: center;
    transition: transform .3s ease-in-out, width .3s ease-in-out;

    .image {
      img {
        width: 100%;
        max-width: 100px;
        height: auto;
      }
    }

    .company-name {
      padding: 10px 5px 0px;
      color: #616161 !important;
    }
  }
}
</style>
