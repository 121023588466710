import departmentHandler from "@/services/Department";

export default {
    // eslint-disable-next-line no-unused-vars
    async loadDepartments({commit, dispatch}, payload) {
        const {data} = await departmentHandler.all(payload);

        commit('SET_DEPARTMENTS', data);
    },
    // eslint-disable-next-line no-unused-vars
    async storeDepartment({commit, dispatch}, payload) {
        return departmentHandler.store(payload);
    },
    // eslint-disable-next-line no-unused-vars
    async updateDepartment({commit, dispatch}, payload) {
        return departmentHandler.update(payload);
    },
    // eslint-disable-next-line no-unused-vars
    async deleteDepartment({commit, dispatch}, payload) {
        return departmentHandler.remove(payload);
    },
    // eslint-disable-next-line no-unused-vars
    async destroyDepartment({commit, dispatch}, payload) {
        return departmentHandler.destroy(payload);
    },
    // eslint-disable-next-line no-unused-vars
    async restoreDepartment({commit, dispatch}, payload) {
        return departmentHandler.restore(payload);
    },
}
