<template>
  <v-app>
    <v-main>
      <router-view name="header"/>
      <router-view/>
      <router-view name="footer"/>
      <snackbar-component/>
    </v-main>
  </v-app>
</template>

<style lang="scss">
</style>
<script>
import SnackbarComponent from "@/components/SnackbarComponent";
export default {
  metaInfo: {
    title: 'GuestPosts.com',
    titleTemplate: 'Admin Dashboard | %s',
    meta: [
      { name: 'robots', content: 'noindex' },
      { name: 'googlebot', content: 'noindex' }
    ]
  },
  components: {
     SnackbarComponent
  }
}
</script>