<template>
  <transition name="slide-fade">
    <div>
      <v-navigation-drawer
          v-model="drawer"
          :clipped="clipped"
          :mini-variant="miniVariant"
          app
          dark
          fixed
          mini-variant-width="100"
          :color="baseColor"
      >
        <app-sidebar-nav :mini-variant="miniVariant"/>
      </v-navigation-drawer>
      <v-app-bar
          :clipped-left="clipped"
          dark
          dense
          elevation="1"
          :color="baseColor"
      >
        <v-app-bar-nav-icon @click.stop="miniVariant = !miniVariant"></v-app-bar-nav-icon>
        <v-btn
            icon
            @click.stop="drawer = !drawer"
            style="margin: 6px 0px"
        >
          <v-icon>{{ `chevron_${drawer ? 'right' : 'left'}` }}</v-icon>
        </v-btn>
        <v-spacer/>
        <div class="mx-2"></div>
        <user-nav/>
      </v-app-bar>
    </div>
  </transition>
</template>

<script>
import AppSidebarNav from "@/components/menu/AppSidebarNav";
import UserMixin from "@/components/mixins/UserMixin";
import UserNav from "@/components/menu/UserNav";

export default {
  name: "AppHeader",
  components: {
    UserNav,
    AppSidebarNav
  },
  mixins: [UserMixin],
  data() {
    return {
      baseColor: this.$vuetify.theme.themes.light.primary,
      clipped: false,
      drawer: false,
      fixed: false,
      miniVariant: false,
      right: true,
      rightDrawer: false
    }
  }
}
</script>

<style lang="scss" scoped>
.v-navigation-drawer--mini-variant .v-list-item > *:nth-child(3) {
  position: relative !important;
  height: 25px !important;
  width: 25px !important;
}

.v-navigation-drawer--mini-variant .v-list-item > :first-child {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.v-navigation-drawer--mini-variant .v-list-group__header {
  padding-right: 0 !important;
}
</style>
