import jobHandler from "@/services/Job";

export default {
    // eslint-disable-next-line no-unused-vars
    async loadJobs({commit, dispatch}, payload) {
        const {data} = await jobHandler.all(payload);

        commit('SET_JOBS', data);
    },
    // eslint-disable-next-line no-unused-vars
    async storeJob({commit, dispatch}, payload) {
        return jobHandler.store(payload);
    },
    // eslint-disable-next-line no-unused-vars
    async updateJob({commit, dispatch}, payload) {
        return jobHandler.update(payload);
    },
    // eslint-disable-next-line no-unused-vars
    async deleteJob({commit, dispatch}, payload) {
        return jobHandler.remove(payload);
    },
    // eslint-disable-next-line no-unused-vars
    async destroyJob({commit, dispatch}, payload) {
        return jobHandler.destroy(payload);
    },
    // eslint-disable-next-line no-unused-vars
    async restoreJob({commit, dispatch}, payload) {
        return jobHandler.restore(payload);
    },
}
