import AppHeader from "@/layouts/AppHeader";
import AppFooter from "@/layouts/AppFooter";

const routes = [
    {
        path: '/login',
        name: 'Login',
        components: {
            default: () => import('../views/Login')
        },
        meta: {guestOnly: true}
    },
    {
        path: '/',
        name: 'Dashboard',
        components: {
            header: AppHeader,
            default: () => import('../views/Dashboard'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/department',
        name: 'Department',
        components: {
            header: AppHeader,
            default: () => import('../views/Departments'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/location',
        name: 'Location',
        components: {
            header: AppHeader,
            default: () => import('../views/Location'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/employee-type',
        name: 'EmployeeType',
        components: {
            header: AppHeader,
            default: () => import('../views/EmployeeType'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/hiring-process',
        name: 'HiringProcess',
        components: {
            header: AppHeader,
            default: () => import('../views/HiringProcess'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/job',
        name: 'Job',
        components: {
            header: AppHeader,
            default: () => import('../views/Job'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/about-us',
        name: 'AboutUs',
        components: {
            header: AppHeader,
            default: () => import('../views/AboutUs'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/join-us',
        name: 'JoinUs',
        components: {
            header: AppHeader,
            default: () => import('../views/JoinUs'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/privacy-policy',
        name: 'PrivacyPolicy',
        components: {
            header: AppHeader,
            default: () => import('../views/PrivacyPolicy'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/job-applicant',
        name: 'JobApplicant',
        components: {
            header: AppHeader,
            default: () => import('../views/Applicant'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    }
];

export default routes;
