<template>
  <v-menu
      :close-on-content-click="menu"
      min-width="250"
      offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-chip
          v-show="showUserName"
          v-on="on"
          v-bind="attrs"
          color="#325bb7"
      >
        <v-icon left small>mdi-account-circle</v-icon>
        {{ userName }}
      </v-chip>
      <v-btn
          v-show="!showUserName" v-on="on" color="primary"
          dark
          fab
          x-small
      >
        <v-icon>mdi-account</v-icon>
      </v-btn>
    </template>
    <v-list>
      <template v-for="(item, key) in navMenuItems">
        <div v-bind:key="key">
          <v-list-item link @click.prevent.stop="routeHandler(item)">
            <v-list-item-icon>
              <v-icon>{{item.icon}}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> {{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider/>
        </div>
      </template>
      <v-list-item link @click.prevent.stop="logoutHandler">
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title> Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {mapState} from 'vuex';
import IsAuthenticated from "@/middleware/IsAuthenticated";

export default {
  name: "UserNav",
  mixins: [IsAuthenticated],
  data() {
    return {
      showBadge: false,
      menu: false,
      navMenuItems: [
        {
          routeName: 'Profile',
          routePath: '/profile',
          icon: 'mdi-account',
          title: 'Profile'
        },
        {
          routeName: 'Settings',
          routePath: '/settings',
          icon: 'mdi-svg',
          title: 'Settings'
        }
      ]
    }
  },
  mounted() {
    this.setUser();
  },
  computed: {
    ...mapState({
      userName: function (state) {
        return state.user && state.user.name ? state.user.name : '';
      }
    }),
    showUserName() {
      return this.$vuetify.breakpoint.mdAndUp;
    }
  },
  methods: {
    async setUser() {
      const {data} = await this.$store.dispatch('auth');
      if(data)  this.$store.commit("SET_USER", data);
    },
    async logoutHandler() {
      await this.$store.dispatch('logout');
      this.localStorageRemoveHandler();
    },
    routeHandler(item) {
      if(item.routeName === this.$route.name) return;
      this.$router.push({name:item.routeName, path: item.routePath});
    },
    localStorageRemoveHandler() {
      localStorage.removeItem("token");
      localStorage.removeItem("auth");
      this.$router.push({name: 'Login'});
    }
  }
}
</script>

<style scoped>

</style>
