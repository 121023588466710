import locationHandler from "@/services/Location";

export default {
    // eslint-disable-next-line no-unused-vars
    async loadLocations({commit, dispatch}, payload) {
        const {data} = await locationHandler.all(payload);

        commit('SET_LOCATIONS', data);
    },
    // eslint-disable-next-line no-unused-vars
    async storeLocation({commit, dispatch}, payload) {
        return locationHandler.store(payload);
    },
    // eslint-disable-next-line no-unused-vars
    async updateLocation({commit, dispatch}, payload) {
        return locationHandler.update(payload);
    },
    // eslint-disable-next-line no-unused-vars
    async deleteLocation({commit, dispatch}, payload) {
        return locationHandler.remove(payload);
    },
    // eslint-disable-next-line no-unused-vars
    async destroyLocation({commit, dispatch}, payload) {
        return locationHandler.destroy(payload);
    },
    // eslint-disable-next-line no-unused-vars
    async restoreLocation({commit, dispatch}, payload) {
        return locationHandler.restore(payload);
    },
}
